
import Blue from "./Blue";

class StripedRock extends Blue {
    constructor(props) {
        super(props)
        this.state = {
            paintings:[ 
            {id:1,src:'https://www.sagishutrit.nl/images/striped-rock/StripedRock1.jpg', func:"dsds",  format:"",  title:"Striped rock", year:"2010"},
            {id:2, src:"https://www.sagishutrit.nl/images/striped-rock/kingkong.gif", func:"", format:"", title:"King kong", year:"2010"},
            {id:3, src:'https://www.sagishutrit.nl/images/striped-rock/StripedRock2.jpg', func:"dsds", format:"", title:"Satelite ", year:"2012"},
            {id:4, src:'https://www.sagishutrit.nl/images/striped-rock/striped_rock.JPG', func:"dsds", format:"", title:"L.A.", year:"2012"},
            {id:5,src:"https://www.sagishutrit.nl/images/striped-rock/strocj5.jpg", func:"dsds", format:"", title:"Asylum seekers", year:"2013"},
            {id:5, src:'https://www.sagishutrit.nl/images/striped-rock/3jun15.jpeg',func:"dsds", format:"", title:"La petite mort", year:"2013"}
          ],
          current:null,
          view:this.views[0]
        }
    }
    componentDidMount(){
        document.getElementById("striped-rock").classList.add("current");
    }
    title= "Striped Rock";
  
}
export default StripedRock;