import  { Component } from 'react';
import thumb1 from '../thumbs/thumb_1.jpg';
import thumb2 from '../thumbs/thumb_2.jpg';
import thumb3 from '../thumbs/thumb_3.jpg';
import thumb4 from '../thumbs/thumb_4.jpg';

class Cantaloupe  extends Component{

    hostname = "https://www.sagishutrit.nl/images/schiele_acantaloupe";

    serie =[{title:"Standing nude girl with long hair",format:"50 x 70, oil on canvas", path:"2024_schiele_a_cantaloupe_1.jpg"},
    {title:"Standing nude facing right",format:"60 x 80, oil on canvas",path:"2024_schiele_a_cantaloupe_2.jpg"},
    {title:"Nude with blue stockings bending forward",format:"60 x 80 oil on canvas",path:"2024_schiele_a_cantaloupe_3.jpg"},
    {title:"Standing woman in a patterned blouse",format:"50 x 70, oil on canvas",path:"2024_schiele_a_cantaloupe_4.jpg"}];
    constructor(props){
        super(props)
        this.state = {
   
            current:0,
            paintings:[],
            mobile:false           
        }
    }
    componentDidMount() {
        document.getElementById("cantaloupe").classList.add("current");
    }
    
    /*  */
    pictureCurrent(a){
        console.log(a);
        this.setState({current:a});
    }
    render() {
        return <div className="row" id="gal-contain">
            <div className="col-md-6 current-painitng">
            <figure>
                    <img style={{maxWidth:'100%'}} src={`${this.hostname}/${this.serie[this.state.current].path}`} alt="Girl behind"/>
                    <figcaption><span>{this.serie[this.state.current].title}</span> <span>{this.serie[this.state.current].format}</span></figcaption>
               </figure>
            </div>
            <div className="col-md-6 thumbs-container">
                <h1>Schiele á cantaloupe</h1>
        
                <div className="le-thumbs">
                    <div className="thumb" onClick={()=>this.pictureCurrent(0)}>
                        <img src={thumb1}/>
                    </div>
                    <div className="thumb" onClick={()=>this.pictureCurrent(1)}>
                    <img src={thumb2}/>
                    </div>
                    <div className="thumb"onClick={()=>this.pictureCurrent(2)}>
                        <img src={thumb3}/>
                    </div>
                    <div className="thumb"onClick={()=>this.pictureCurrent(3)}>
                        <img src={thumb4}/>
                    </div>
                </div> 
                <div className="article">
                    <p>
                    The works repeat the compositions of <a href="https://en.wikipedia.org/wiki/Egon_Schiele">Egon Schiele</a>. The original drawings and paintings were painted by Schiele in the years between 1913-1916.
                    The subjects introduce the viewer to the meta-levels of perception, between resilience and presence, between sublime and subconsciousness.
                    </p>
                </div>       
            </div>
        </div>
    }
}
export default Cantaloupe