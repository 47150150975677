import { Component } from "react";
class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pristine:false,
            left:null,
            right:null,
            style:{
                backgroundColor:'orange'
            }
        }
    }
    componentDidMount(){
      this.setState({left:true})
    }
    componentWillUnmount(){
        console.log("out",this.state.left)
    }
    mkApparent(str){
        console.log(str);
    }
    addCurrent() {
        this.setState({left:true})
        console.log("State", this.state)
    }
    removeCurrent() {
        this.setState({left:false})
        console.log("State", this.state)
    }
    render(){
        return(
            <div className="row">
                <img src="https://www.sagishutrit.nl/images/events/splash_pre_image.jpg" alt="e" style={{maxWidth: '500px',display: 'block', marginRight: 'auto', marginLeft: 'auto'}}></img>
            </div>
    
        )
    }
}

export default Home