import  { Component } from 'react';
class Collabo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first:false,
            projectDetails:'',
            main:{display:'flex', justifyContent:'space-around',alignItems:'center', border:'1px solid purple', height:'calc(100vh - 200px)'},
            style:{border:'1px dashed green', width:'250px', height:'250px'}

        }    
    }
    purpose= 'Should show at lest two square divs. Clickable so I can see more about the project';
    location = 'your parent div is: col-sm-12 col-md-10 tmuna'
    mainView() {
       return <div className='colk' style={this.state.main}>
        <div className='proje' style={this.state.style}>
        <span>Graphic Design</span>
        </div>
        <div className='proje' style={this.state.style}><span>Ink on paper</span></div>
       
       </div>;        
    }
    render() {
        return this.mainView();
    }
}

export default Collabo