import Blue from "./Blue";
class AcidBloom extends Blue  {
    constructor(props) {
        super(props)
        this.state = {
            paintings:[ 
            {id:2, src:"https://www.sagishutrit.nl/images/acid-bloom/comp12.jpeg", func:"none", format:"50x50", title:"Hobbemaplein", year:"2021"},
            {id:3, src:'https://www.sagishutrit.nl/images/acid-bloom/October_AB.jpg', func:"none", format:"50x50", title:"Untitled", year:"2020"},
            {id:4,src:'https://www.sagishutrit.nl/images/acid-bloom/14adar_wedding.jpeg', func:"none",  format:"80x32",  title:"Untitled", year:"2021"},
            {id:5, src:"https://www.sagishutrit.nl/images/acid-bloom/acid-bloom-july.jpg ", func:"none", format:"50x50", title:"Waalsdoperweg", year:"2021"},
            {id:1,src:'https://www.sagishutrit.nl/images/acid-bloom/comp11.jpg', func:"none",  format:"50x50",  title:"jane", year:"2021"},
            {id:6, src:'https://www.sagishutrit.nl/images/acid-bloom/acid-bloom-august.jpg ', func:"none", format:"50x50", title:"Waalsdorperweg", year:"2021"},
            {id:7, src:'https://www.sagishutrit.nl/images/acid-bloom/bythesea.jpeg', func:"none", format:"50x40", title:"untitled", year:"2021"},
            {id:8, src:'https://www.sagishutrit.nl/images/acid-bloom/horse.jpeg',func:"none", format:"60x50", title:"Horsey", year:"2021"}
          ],
          current:null,
          view:this.views[0]
        }
    }    
    componentDidMount(){
        document.getElementById("acid-bloom").classList.add("current");
        console.log('acid bloom!!!');
    }
    title= "Acid bloom";
    
}
export default AcidBloom;