import  { Component } from 'react';
import axios from 'axios';
class Contact extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            msgUser:'',
            inValid:'invalid',
            msgColor:'#d92b26',
            msgColorError:'#191919',
            fontWeight:'bold',
            msgColorGreen:'#00ad26',
            disabled:true,
            newLetterVisible:true
        }
    }
    componentDidMount(){
        document.getElementById("contact").classList.add("current");
    }
    validateAddress(email) {
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            this.setState({disabled:false})
        } else {
            this.setState({disabled:true})
        }        
    }
    toggleForm() {
      setTimeout(()=>{
        const newsLetter = document.getElementById("mailer");
        newsLetter.style.display ="none";
      },2000);
    }
    subscribe() {
        const url ="https://sagishutrit.nl/api/mailer.php?";
        axios.post(url,`email=${this.user_email}`).then((res)=>{   
            if(res.data.status==='200') {
                this.setState({msgColor:'#333',msgUser:'Thank you for subscribing!',fontWeight:this.state.fontWeight});
                this.toggleForm();                
            }
            if(res.data.status==='304') {
                this.setState({msgColor:this.state.msgColorError,
                    msgUser:'This Email is used',
                    fontWeight:this.state.fontWeight,
                    disabled:true
                });
                this.toggleForm();
            }     
        }); 
       
    }

    handleChange(event) {
        this.user_email = event.target.value;
        this.setState({msgUser:null})
        this.validateAddress(this.user_email);
    } 
    Invalid() {
        this.setState({msgUser:'Invalid Email',msgColor:this.state.msgColorError})
    }

    render() {
        return ( <div className="row" style={{backgorund:'red', border:'border 1px solid purple'}}>
                <div className="col-sm-12 col-md-6 concol">
            <figure style={{ margin:'0 auto',border:'0px solid gold' }}>
                <img id="studio-img" src="https://denhaag.com/sites/default/files/styles/image_paragraph_460x260/public/39f5ef71-db07-4ecc-8a7d-20ae58b0a473.jpg?h=4521fff0&itok=adztRNI0" alt=""/>
                <figcaption></figcaption>
            </figure>
        </div>
        <div className="col-sm-12 col-md-6 concol">
        <div style={{ margin:'0 auto', display:'flex', flexDirection:'column'}} className='address'>
        <h5>Atelier</h5>
            <p>Prinsegracht 16</p>
            <p>2512 GA Den Haag</p>
            <p>e-mail:   admin@sagishutrit.nl</p>
            <span style={{color: '#999999'}}><em>KvK nummer: 5187505</em></span>
            </div>
        <br/><br/>
        <div className="mail-subscribe" id="mailer">  
                    <div className='form-container'>
                        <label>SIGN UP FOR THE NEWSLETTER</label><br />
                        <input id="emailAddress"
                        type="email"
                        placeholder="your email-address"
                        size="45"
                        maxLength="40"
                        style={{borderBottom:'1px solid white'}}
                        onChange={event => {this.handleChange(event)}}
                        multiple />
                        <button id="email_subscribe" onClick={()=>this.subscribe()} disabled={this.state.disabled}>&#x21E8;</button>
                    </div>
                    <span style={{color:this.state.msgColor, fontWeight:this.state.fontWeight,letterSpacing:'1px', textTransform:'uppercase'  }} className='ertx'>{this.state.msgUser}</span>
                </div> 

    </div>
    </div>
        )
    }
}

export default Contact