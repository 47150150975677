import  { Component } from 'react';
import axios from 'axios';

class About extends Component {

    constructor(props){
        super(props)
        this.state = {
            public:['one', 'two'],
            article:"During that time Sagi worked as a painter and performance artist",
            image:"https://www.sagishutrit.nl/images/profile/sagi_langevoorhuit.jpg",
            title:'About',
            text:""

        }
    }
    componentDidMount() {
        document.getElementById("about").classList.add("current");  
        this.egtTboua();     
    }

    egtTboua(){
        // make a request for the about page content and return it in a state. 
        const value = 'true';
        const  url ="https://sagishutrit.nl/api/about.php";
        axios.post(url,`about=${value}`).then(res=>{
            console.log("response:", res.data.content);
            const data = res.data.content;
            this.setState({text:data.content });
            console.log("bingo:", this.state);
    }) 
    }

    onChange() {
        console.log(this.props);
    }

    render(){
        return(<div className='row'>
                <div className='col-sm-12 col-md-6'><img style={{maxWidth:'100%'}} src={this.state.image} alt='artist-profile'/></div>
                <div className='col-sm-12 col-md-6 content-about-width' style={{border:'0px solid green'}}>
                <p style={{color:'#4d4d4d', fontSize:'20px', padding:'4px',width:'88%', fontWeight:'400',textAlign:'justify',lineHeight:'1.8'}}>{this.state.text}</p>
                </div>
        </div>);
    }
    
}
export default About